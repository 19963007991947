.SocialMedia{
    position: fixed; 
    z-index: 1000;
    max-width: 4rem;
    padding-top: 8rem;
        bottom: -1rem;   
        margin-left: 1.0rem;   
}
ul {
 list-style: none; 
}
.SocialLogos {
    padding: 1rem;
  margin-top: -2rem;
    

}
