.Contact {
  position: relative;
  z-index: 1;
  
}
.app-title span {
  font-size: 1rem; /* Default font size */
  color: #ffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app-title span {
    font-size: 1.5rem; /* Smaller font size on smaller screens */
  }
}

@media (max-width: 480px) {
  .app-title span {
    font-size: 1rem; /* Even smaller font size on very small screens */
  }
}


.background { /* Main body settings */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 9rem;
  padding-bottom: 1rem;
  padding-top: 4rem;
}

  
  body {
    background: #263849;
    font-size: 1rem;
    
  }
  
  body, button, input {
    font-family: 'Roboto Mono';
    font-weight: 700;
    letter-spacing: 1.4px;
  }
  
  .background {
    display: auto;
    min-height: 100vh;
  }
  
  .contactContainer {
    flex:  1 600px;
   width: auto;
  }
  
  .screen {
    position: relative;
    background: #3e3e3e;
    border-radius: 15px;
  }
  
  .screen:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, .4);
    z-index: -1;
  }
  
  .screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #4d4d4f;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  

  .screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: white;
  }
  
  .screen-header-button.close {
    background: #ed1c6f;
  }
  
  .screen-header-button.maximize {
    background: #e8e925;
  }
  
  .screen-header-button.minimize {
    background: #74c54f;
  }
   
   .screen-body {
    display: flex;
  }
  
  .screen-body-item {
    flex: 1;
    padding: 50px;
  }
  
  .screen-body-item.left {
    display: flex;
    flex-direction: column;
  }
  
  .app-title {
    display: flex;
    flex-direction: column;
    position: auto;
    color:rgb(47, 255, 0);
    font-size:2.5rem;
  }
  
  .app-contact {
    
    margin-top: Auto;
    font-size: 1.65rem;
    color: #ffff;
  }
  
  .app-form-group {
    margin-bottom: 1px;
  }
  
  .app-form-group.message {
    margin-top: 40px;
  }
  
  .app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
  }
  
  .app-form-control {
    width: 100%;
    padding: 10px 0;
    font-family: 'Roboto Mono';
    font-weight: 700;
    letter-spacing: auto;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: #ddd;
    font-size: 2rem;
    text-transform: uppercase;
    outline: none;
    transition: border-color .2s;
  }
  
  .app-form-control::placeholder {
    color: #666;
  }
  
  .app-form-control:focus {
    border-bottom-color: #ddd;
  }
  
  .app-form-button {
    background: none;
    border: none;
    color:rgb(47, 255, 0);
    padding-right: 6rem;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
  }
  .app-form-button-send{
    background: none;
    border: none;
    
    color:rgb(47, 255, 0);
    font-size: 2rem;
    cursor: pointer;
    outline: none;
  }
  
  .app-form-button:hover {
    color: #90f6d7;
  }
  

  @media (max-width: 768px){
    .screen-body {
      flex-direction: column;
      
    }
    .background  {
      padding:2rem;
    }
    .screen-body-item.left {
      margin-bottom: 30px;
    }
  
    .app-title {
      flex-direction: row;
      color:rgb(47, 255, 0);
    }
  
    .app-title span {
      margin-right: 12px;
    }
  
    .app-title:after {
      display: none;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .screen-body {
      padding: 10%;
    }
  
    .screen-body-item {
      padding: 0;
    }
  }
  .thanks{
    padding-bottom: 6rem;
    color:rgb(47, 255, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    /* other styles */
  }