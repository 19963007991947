.nav-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}

.navitems ul {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: 4.5rem;
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
    background-color: #263849;
    background-size: cover;
    color: #ffffff;
    text-decoration: none;
    margin: 0;
    padding: 0;
    opacity: 95%;
}

.navitems li {
    padding-top: 1.5rem;
    margin: 0 1.5rem;
}

.navitems a:hover, .navitems li a:hover {
    text-decoration: underline;
}

.resume-button {
    background-color: #41506b;
    color: white;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.resume-button:hover {
    background-color: #81a6b1;
}

/* Responsive Styles for screens less than or equal to 650px */

