.App
{
  width: 100%; /* Occupy the entire width of the viewport */
  max-width: Auto; /* Limit the maximum width to 320px */
  margin: 0 auto; /* Center the element horizontally */
  padding: 6.25rem 2.5rem;
  text-align: left; /* Center the content within the element */
}
@font-face {
  font-family: 'Roboto Mono';
  src: url('./components/Fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') ;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./components/Fonts/Roboto_Mono/RobotoMono-Italic-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: italic;
}
.body{
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}
@media (min-width: 320px) {
  /* Styles for viewport width >= 320px */
}

@media (min-width: 375px) {
  /* Styles for viewport width >= 375px */
}

@media (min-width: 425px) {
  /* Styles for viewport width >= 425px */
}

@media (min-width: 768px) {
  /* Styles for viewport width >= 768px */
}
/* primary Colors */
.first-color { 
	background: #90f6d7; 
}
	
.second-color { 
	background: #35bcbf; 
}

.third-color { 
	background: #41506b; 
}

.fourth-color { 
	background: #263849; 
}
/* Secondary Colors */
.first-color { 
	background: #58828b; 
}
	
.second-color { 
	background: #5e9387; 
}

.third-color { 
	background: #c8e29d; 
}

.fourth-color { 
	background: #f2f299; 
}
/* https://palettes.shecodes.io/palettes/1281 */