.Footer-container {
  
   /* */
    margin-top: -4rem;
  display: grid;
  place-items: center;
  color: #ffffff;
  text-decoration: none; 
  position: relative;
  z-index: 1;
padding-bottom: 2rem;

}

a {
    color: inherit; /* This makes the link color the same as the parent element */
    text-decoration: none; /* This removes the underline from links */
  }
  
  a:hover {
    text-decoration: underline; /* Optional: adds underline on hover for visual feedback */
  }
  